// Windows 8 mobile Responsive utility
if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
  var msViewportStyle = document.createElement('style')
  msViewportStyle.appendChild(
    document.createTextNode(
      '@-ms-viewport{width:auto!important}'
    )
  )
  document.querySelector('head').appendChild(msViewportStyle)
}

jQuery(document).ready(function () {
  // LazyLoading
  jQuery("img").unveil(500, function () {
    jQuery(this).on('load', function () {
      jQuery(this).removeAttr("data-src");
    });
  });
  jQuery("#nav-opener").click(function(){
    jQuery(this).toggleClass("is-active");
    jQuery("#nav-container").toggleClass("is-active");
  });
  jQuery("#banner-slider").slick({
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    fade: true,
    cssEase: 'ease'
  });
  jQuery(".gallery a").simpleLightbox({
    showCounter: false,
    history: false
  });
});

jQuery(document).scroll(function() {
  if (jQuery(document).scrollTop() >= 100) {
    jQuery('.header').addClass("scrolled");
  } else {
    jQuery('.header').removeClass("scrolled");
  }
});
